
import CompTable from "@/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "套餐名称",
                    key: "setMealName",
                },
                {
                    title: "套餐金额",
                    render: (h: Function, params: obj) => "￥" + ((params.row?.setMealAmount || 0) / 100).toFixed(2),
                },
                {
                    title: "第一年折后价",
                    render: (h: Function, params: obj) => "￥" + ((params.row?.firstYearOfDiscountPrice || 0) / 100).toFixed(2),
                },
                {
                    title: "第二年折后价",
                    render: (h: Function, params: obj) => "￥" + ((params.row?.secondYearOfDiscountPrice || 0) / 100).toFixed(2),
                },
                {
                    title: "第三年折后价",
                    render: (h: Function, params: obj) => "￥" + ((params.row?.thirdYearOfDiscountPrice || 0) / 100).toFixed(2),
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "row-operate",
                    width: 200,
                },
            ],
        }
    },
}
