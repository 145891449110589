import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!

  return (_openBlock(), _createBlock(_component_comp_table, {
    ref: "comp_table",
    title: "套餐管理",
    entity: "Goods",
    columns: $data.columns,
    "table-api": "WEB://SetMeal/FindAllToPage",
    "delete-api": "WEB://SetMeal/Delete"
  }, {
    search: _withCtx((evt) => [
      _createVNode(_component_Input, {
        modelValue: evt.search.setMealName,
        "onUpdate:modelValue": ($event: any) => ((evt.search.setMealName) = $event),
        placeholder: "套餐名称"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    operate: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "info",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/meal/form')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("新增")
        ]),
        _: 1
      })
    ]),
    "row-operate": _withCtx((params) => [
      _createVNode(_component_Button, {
        type: "info",
        style: {"margin":"3px"},
        onClick: ($event: any) => (_ctx.$router.push('/meal/form?id=' + params.row.uuid))
      }, {
        default: _withCtx(() => [
          _createTextVNode("编辑")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_Button, {
        type: "error",
        style: {"margin":"3px"},
        onClick: ($event: any) => (params.event.delete({ uuid: params.row.uuid }))
      }, {
        default: _withCtx(() => [
          _createTextVNode("删除")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["columns"]))
}